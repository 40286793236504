.pdf {
    float: right;
    border: 2px solid #90c249;
}

.borderColor {
    border: 2px solid #90c249;
}

.buttonMargins{
    margin-left: 2px;
    margin-right: 2px;
}

.tableCorners{
    border-radius: 5px;
}

.headerButtons {
    position: absolute;
    top: 30px;

}

.headerButton {
    margin-right: 10px;
    margin-left: 120px;
}

.newMapButton{
    margin-left: 10px;
}

.buttonStyling {
    background: transparent;
    border: 0;
    color: white;
}

.buttonStyling:hover {
    color: black;
}