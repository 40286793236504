.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
  color: whitesmoke;
}

.App .navbar-default {
  background-color: #88b8ff;
}

.App .navbar-nav>li>a {
  color: white;
}