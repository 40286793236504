.containerStyle {
    height: 500px;
}

.sleepingBag{
    margin-left: 20px;
}

.tent{
    margin-left: 20px;
}

.icons{
    width: 40px;
    height: 40px;
}